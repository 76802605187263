export default function Alert({ message }: { message: string }) {

    return (
        <>
            {message &&
                <div className="alert_box" onClick={() => {message = ""}}>
                    {message}
                </div>
            }
        </>
    )
}
