import Alert from "js/Alert";
import { Requester, ServerStatus } from "js/Requester";
import { ConstructorTrain } from "js/types";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { checkServerStatusAndDo, toggleTopicPublish } from "./jsx/common";
import { renderedTopicsList, renderedTrainsList } from "./jsx/renderedLists";

export default function TrainPool() {

    const navigate = useNavigate();

    const [allTrains, setAllTrains] = React.useState<ConstructorTrain[]>([]);

    const [trainFilter, setTrainFilter] = React.useState<string>("");

    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const alrt = (msg: string) => {
        setAlertMessage(msg);
        setTimeout(() => setAlertMessage(""), 2500);
    }

    const loadTrains = async () => {
        const [status, payload] = await Requester.getAllTrains();
        checkServerStatusAndDo(status, payload, navigate, () => {
            payload && setAllTrains(payload);
        });
    }

    React.useEffect(() => {
        loadTrains();
    }, [])

    const deleteTrain = async (id: number) => {
        const [status, payload] = await Requester.deleteTrain(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            setAllTrains(allTrains.filter((e: ConstructorTrain) => e.id !== id));
            alrt(`Тренировка удалена`);

        });
    }


    const togglePublish = async (e: ConstructorTrain) => {
        e.publish = !e.publish;
        const [status, payload] = await Requester.changeTrain(e);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt(`Тренировка ${e.publish ? "опубликована" : "скрыта"}`);
            loadTrains();
        });
    }

    const renderedTrains = useMemo(() =>
        renderedTrainsList(
            allTrains,
            trainFilter,
            null,
            togglePublish,
            deleteTrain,
            navigate,
            null
        ), [allTrains]);
    return (
        <div className="main_container">
            <Alert message={alertMessage} />
            <input className="constructor_input" type="text" placeholder="filter words" value={trainFilter} onChange={(e) => setTrainFilter(e.target.value)} />
            <div className="words_list">
                {renderedTrains}
            </div>
        </div>
    );
}
