import { Cookier } from "js/Cookier";
import { Requester, ServerStatus } from "js/Requester";
import React from "react"
import { useNavigate } from "react-router-dom";

export default function Enter() {

    const navigate = useNavigate();

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    const enter = async () => {
        const [status, payload] = await Requester.login(username, password);
        if (status === ServerStatus.error) {
            alert("error");
            return;
        }
        if (status === ServerStatus.unauth) {
            alert("wrong password!");
            return;
        }
        if (payload?.token) {
            Cookier.saveToken(payload.token);
            window.location.href = "/";
        }
    }

    return (
        <div className="enter_box">
            <input type="text" placeholder="username" value={username} onChange={(e) => setUsername(e.target.value)} />
            <input type="text" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <button
                onClick={enter}
            >Войти</button>
        </div>
    );
}
