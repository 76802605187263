import { Requester, ServerStatus } from "js/Requester";
import { Sound, Word } from "js/types";
import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import editIco from "static/icons/edit.svg";
import removeIco from "static/icons/remove.svg";
import soundIco from "static/icons/sound.svg";
import nosoundIco from "static/icons/nosound.svg";
import Alert from "js/Alert";
import { checkServerStatusAndDo } from "./course/jsx/common";

export default function SoundPool() {

    const navigate = useNavigate();

    const [allSounds, setAllSounds] = React.useState<Sound[]>([]);

    const [soundFilter, setSoundFilter] = React.useState<string>("");

    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const alrt = (msg: string) => {
        setAlertMessage(msg);
        setTimeout(() => setAlertMessage(""), 2500);
    }

    const loadSounds = async () => {
        const [status, payload] = await Requester.getAllSounds();
        checkServerStatusAndDo(status, payload, navigate, () => {
            payload && setAllSounds(payload);
        });
    }

    React.useEffect(() => {
        loadSounds();
    }, [])

    const deleteSound = async (id: number) => {
        const [status, payload] = await Requester.deleteSound(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            setAllSounds(allSounds.filter((e: Sound) => e.id !== id));
            alrt("Слово удалено");
        });
    }

    const renderedSounds = allSounds
        .filter((e: Sound) => (e.title).includes(soundFilter))
        .map((e: Sound) =>
            <div key={e.id} className="word fl_evenly">
                <div className="word_section fl_center el_w30">
                    <div className="word_section_val">{e.title}</div>
                </div>
                <div className="word_section word_button fl_center el_w10">
                    {e.sound &&
                        <>
                            <img src={soundIco} onClick={() => {
                                const audio = new Audio(Requester.static_url + "words/sounds/" + e.sound + ".mp3");
                                audio.play();
                            }} />
                        </>
                    }
                    {!e.sound &&
                        <img src={nosoundIco} />
                    }
                </div>
                <div className="word_section word_button fl_evenly el_w10">
                    <img src={editIco} onClick={() => navigate(`/sounds/edit?soundId=${e.id}`)} />
                    <img src={removeIco} onClick={() => deleteSound(e.id)} />
                </div>
            </div>
        );

    return (
        <div className="main_container">
            <Alert message={alertMessage} />
            <button onClick={() => navigate("/")} className="constructor_button">На главную</button>
            <input className="constructor_input" type="text" placeholder="filter words" value={soundFilter} onChange={(e) => setSoundFilter(e.target.value)} />
            <div className="words_list">
                {renderedSounds}
            </div>
        </div>
    );
}
