import { Link, Route, Routes, useNavigate } from "react-router-dom";
import Enter from "screens/Enter";
import WordPool from "screens/WordPool";
import Links from "screens/Links";
import WordConstructor from "screens/WordConstructor";
import WordsRoomsPool from "screens/WordsRoomsPool";
import WordsRoomConstructor from "screens/WordsRoomConstructor";
import ChapterPool from "screens/course/ChapterPool";
import ChapterConstructor from "screens/course/ChapterConstructor";
import TopicPool from "screens/course/TopicPool";
import TopicConstructor from "screens/course/TopicConstructor";
import TrainPool from "screens/course/TrainPool";
import TrainConstructor from "screens/course/TrainConstructor";
import { Requester } from "js/Requester";
import { checkServerStatusAndDo } from "screens/course/jsx/common";
import React from "react";
import { on } from "events";
import WordsChapterPool from "screens/course/WordsChapterPool";
import WordsChaptersConstructor from "screens/course/WordsChapterConstructor";
import SoundConstructor from "screens/SoundConstructor";
import SoundPool from "screens/SoundPool";

function App() {

    const navigate = useNavigate();

    const [logined, setLogined] = React.useState<boolean>(false);

    const checkLogin = async () => {
        const [status, payload] = await Requester.checkLogin();
        checkServerStatusAndDo(status, payload, navigate, () => {
            payload && setLogined(true);
        });
    }


    React.useEffect(() => {
        checkLogin();
        console.log("checking login");
    }, [])

    return (
        <div className="app_screen">
            <Routes>
                <Route path="/enter" element={<Enter />} />
                <Route path="/*" element={<AppScreen logined={logined} />} />
            </Routes>
        </div>
    );
}

function AppScreen({ logined }: { logined: boolean }) {

    const navigate = useNavigate();

    return (
        <>
            {logined
                ?
                <>
                    <div className="app_screen">
                        <div className="side_menu_area">

                            <div className="side_menu_header">Лексика</div>
                            <div className="side_menu_item">
                                <Link to="/words_chapters" className="text">Главы слов</Link>
                                <button onClick={() => navigate("/words_chapters/add")}>+</button>
                            </div>
                            <div className="side_menu_item">
                                <Link to="/words_rooms" className="text">Комнаты слов</Link>
                                <button onClick={() => navigate("/words_rooms/add")}>+</button>
                            </div>
                            <div className="side_menu_item">
                                <Link to="/words" className="text">Слова</Link>
                                <button onClick={() => navigate("/words/add")}>+</button>
                            </div>

                            <div className="side_menu_header">Грамматика</div>
                            <div className="side_menu_item">
                                <Link to="/chapters" className="text">Главы</Link>
                                <button onClick={() => navigate("/chapters/add")}>+</button>
                            </div>
                            <div className="side_menu_item">
                                <Link to="/topics" className="text">Темы</Link>
                                <button onClick={() => navigate("/topics/add")}>+</button>
                            </div>
                            <div className="side_menu_item">
                                <Link to="/trains" className="text">Тренировки</Link>
                                <button onClick={() => navigate("/trains/add")}>+</button>
                            </div>
                            <div className="side_menu_header">Звуки</div>
                            <div className="side_menu_item">
                                <Link to="/sounds" className="text">Звуки</Link>
                                <button onClick={() => navigate("/sounds/add")}>+</button>
                            </div>

                        </div>
                    </div>
                    <div className="center_area">
                        <Routes>
                            <Route path="words" element={<WordPool />} />
                            <Route path="words/add" element={<WordConstructor />} />
                            <Route path="words/edit" element={<WordConstructor />} />
                            <Route path="words_rooms" element={<WordsRoomsPool />} />
                            <Route path="words_rooms/add" element={<WordsRoomConstructor />} />
                            <Route path="words_rooms/edit" element={<WordsRoomConstructor />} />

                            <Route path="chapters" element={<ChapterPool />} />
                            <Route path="chapters/add" element={<ChapterConstructor />} />
                            <Route path="chapters/edit" element={<ChapterConstructor />} />
                            
                            <Route path="words_chapters" element={<WordsChapterPool />} />
                            <Route path="words_chapters/add" element={<WordsChaptersConstructor />} />
                            <Route path="words_chapters/edit" element={<WordsChaptersConstructor />} />

                            <Route path="topics" element={<TopicPool />} />
                            <Route path="topics/add" element={<TopicConstructor />} />
                            <Route path="topics/edit" element={<TopicConstructor />} />

                            <Route path="trains" element={<TrainPool />} />
                            <Route path="trains/add" element={<TrainConstructor />} />
                            <Route path="trains/edit" element={<TrainConstructor />} />

                            <Route path="sounds" element={<SoundPool />} />
                            <Route path="sounds/add" element={<SoundConstructor />} />
                            <Route path="sounds/edit" element={<SoundConstructor />} />
                        </Routes>
                    </div>
                </>
                :
                <> </>
            }
        </>
    );
}

export default App;
