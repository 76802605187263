import Alert from "js/Alert";
import { Requester, ServerStatus } from "js/Requester";
import { emptyWordsRoom, Word, WordsRoom } from "js/types";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import soundIco from "static/icons/sound.svg";
import nosoundIco from "static/icons/nosound.svg";
import plusIco from "static/icons/plus.svg";
import minusIco from "static/icons/minus.svg";

export default function WordsRoomConstructor() {

    const navigate = useNavigate();

    const [isNewRoom, setIsNewRoom] = React.useState<boolean>(true);
    const [alertMessage, setAlertMessage] = React.useState<string>("");

    const [allWords, setAllWords] = React.useState<Word[]>([]);
    const [wordList, setWordList] = React.useState<Word[]>([]); // selected words

    const [wordFilter, setWordFilter] = React.useState<string>("");
    const [wordFilterSelected, setWordFilterSelected] = React.useState<string>("");
    const [wordsRoom, setWordsRoom] = React.useState<WordsRoom>(emptyWordsRoom());
    
    const [rerender, setRerender] = React.useState<number>(0);

    const id = useRef<number>(-1);

    const tryToLoadWordsRoom = async () => {
        console.log("trying to load word");
        const urlParams = new URLSearchParams(window.location.search);
        const wordsRoomId: number | null = Number(urlParams.get("wordsRoomId"));

        id.current = wordsRoomId;

        if (!wordsRoomId) {
            return;
        }
        setIsNewRoom(false);

        const [status, payload] = await Requester.getWordsRoom(wordsRoomId);
        if (status === ServerStatus.error) {
            alert("error");
            return;
        } else if (status === ServerStatus.unauth) {
            alert("wrong password!");
            navigate("/enter");
            return;
        } else if (status === ServerStatus.not_found) {
            alert("Не смог найти слово по id в адерсной строке");
            navigate("/words");
            return;
        }
        if (payload) {
            id.current = payload.id;
            setWordsRoom(payload);
            console.log(payload)
            if (payload.wordList) setWordList(payload.wordList);
        }
    }

    const loadWords = async () => {
        const [status, payload] = await Requester.getAllWords();
        if (status === ServerStatus.error) {
            alert("error");
            return;
        } else if (status === ServerStatus.unauth) {
            alert("wrong password!");
            navigate("/enter");
            return;
        }
        if (payload) {
            setAllWords(payload);
        }
    }

    React.useEffect(() => {
        tryToLoadWordsRoom();
        loadWords();
    }, [])


    const saveWordsRoom = async () => {
        if (!wordsRoom.title) {
            alert("Название необходимо");
            return;
        }
        const [status, payload] = await Requester.saveWordsRoom(wordsRoom);
        if (status === ServerStatus.error) {
            alert("error");
            return;
        }
        if (status === ServerStatus.unauth) {
            alert("unauth");
            return;
        }
        if (payload) {
            console.log(payload);
            setAlertMessage("Комната слов сохранена");
            setTimeout(() => setAlertMessage(""), 2500);
        }
    }

    const updateWordsRoom = async () => {
        if (!wordsRoom.title) {
            alert("Название необходимо");
            return;
        }
        const [status, payload] = await Requester.changeWordsRoom(wordsRoom);
        if (status === ServerStatus.error) {
            alert("error");
            return;
        }
        if (status === ServerStatus.unauth) {
            alert("unauth");
            return;
        }
        if (payload) {
            console.log(payload);
            setAlertMessage("Комната слов изменена");
            setTimeout(() => setAlertMessage(""), 2500);
        }
    }

    const renderedSelectedWords = wordList
        .filter((e: Word) => (e.kazakh.reduce((tot, val) => tot + val, "") + e.russian.reduce((tot, val) => tot + val, "")).includes(wordFilterSelected))
        .map((e: Word) =>
            <div key={e.id} className="word fl_evenly">
                <div className="word_section fl_center el_w30">
                    <div className="word_section_val">{e.kazakh[0]}</div>
                </div>
                <div className="word_section fl_center el_w30">
                    <div className="word_section_val">{e.russian[0]}</div>
                </div>
                <div className="word_section fl_center el_w20">
                    <img src={Requester.static_url + "words/images/" + e.image + ".jpg"} />
                </div>
                <div className="word_section word_button fl_center el_w10">
                    {e.sound &&
                        <>
                            <img src={soundIco} onClick={() => (document.getElementById("tt") as HTMLAudioElement).play()} />
                            <audio id="tt">
                                <source src={Requester.static_url + "words/sounds/" + e.sound + ".mp3"} type="audio/mp3" />
                            </audio>
                        </>
                    }
                    {!e.sound &&
                        <img src={nosoundIco} />
                    }
                </div>
                <div className="word_section word_button fl_evenly el_w10">
                    <img src={minusIco} onClick={() => {
                        wordList.splice(wordList.indexOf(e), 1);
                        setRerender(rerender+1);
                    }} />
                </div>
            </div>
        );

    const renderedWords = allWords
        .filter((e: Word) => ((e.kazakh.reduce((tot, val) => tot + val, "") + e.russian.reduce((tot, val) => tot + val, "")).includes(wordFilter) && !(wordList.map(e => e.id).includes(e.id))))
        .map((e: Word) =>
           <div key={e.id} className="word fl_evenly">
                <div className="word_section fl_center el_w30">
                    <div className="word_section_val">{e.kazakh[0]}</div>
                </div>
                <div className="word_section fl_center el_w30">
                    <div className="word_section_val">{e.russian[0]}</div>
                </div>
                <div className="word_section fl_center el_w20">
                    <img src={Requester.static_url + "words/images/" + e.image + ".jpg"} />
                </div>
                <div className="word_section word_button fl_center el_w10">
                    {e.sound &&
                        <>
                            <img src={soundIco} onClick={() => (document.getElementById("tt") as HTMLAudioElement).play()} />
                            <audio id="tt">
                                <source src={Requester.static_url + "words/sounds/" + e.sound + ".mp3"} type="audio/mp3" />
                            </audio>
                        </>
                    }
                    {!e.sound &&
                        <img src={nosoundIco} />
                    }
                </div>
                <div className="word_section word_button fl_evenly el_w10">
                    <img src={plusIco} onClick={() => {
                        wordList.push(e);
                        setRerender(rerender+1);
                    }} />
                </div>
            </div>
        );

    return (
        <>
            <Alert message={alertMessage} />
            <div className="main_container">

                <button onClick={() => navigate("/words_rooms")} className="constructor_button">Назад</button>
                <button className="constructor_button" onClick={() => isNewRoom ? saveWordsRoom() : updateWordsRoom()}>Сохранить комнату слов</button>
                <button className="constructor_button" onClick={() => setWordsRoom({...wordsRoom, hidden: !wordsRoom.hidden})}>{wordsRoom.hidden ? "Скрытая комната: да" : "Скрытая комната: нет"}</button>

                {isNewRoom || <div className="load_title_and_button">{`https://lingolope.com/lexis/overview?id=${id.current}`}</div>}

                <div className="load_title_and_button">
                    <div className="el_w50">
                        Название комнаты слов
                    </div>

                    <div className="el_w50">
                        <input className="constructor_input el_w100" id="kz_input" placeholder="title" type="text" value={wordsRoom.title} onChange={e => setWordsRoom({...wordsRoom, title: e.target.value})} />
                    </div>
                </div>

                <div className="words_list">
                    Выбранные слова:
                    <input className="constructor_input" type="text" placeholder="filter words" value={wordFilterSelected} onChange={(e) => setWordFilterSelected(e.target.value)} />
                    {renderedSelectedWords}
                </div>

                <div className="words_list">
                    Все слова:
                    <input className="constructor_input" type="text" placeholder="filter words" value={wordFilter} onChange={(e) => setWordFilter(e.target.value)} />
                    {renderedWords}
                </div>

            </div>
        </>
    );

}
