import Alert from "js/Alert";
import { Requester, ServerStatus } from "js/Requester";
import { AutoTaskTemplate, AutoTaskTemplateEnum, ConstructorTrain, emptyConstructorTrain } from "js/types";
import React, { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { checkServerStatusAndDo } from "./jsx/common";

export default function TrainConstructor() {

    const navigate = useNavigate();

    const [isNewTrain, setIsNewTrain] = React.useState<boolean>(true);
    const [title, setTitle] = React.useState<string>("");

    const [train, setTrain] = React.useState<ConstructorTrain>(emptyConstructorTrain());

    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const alrt = (msg: string) => {
        setAlertMessage(msg);
        setTimeout(() => setAlertMessage(""), 2500);
    }

    const [autotasksTemplates, setAutotasksTemplates] = React.useState<string[]>([]);

    const id = useRef<number>(-1);

    const tryToLoadTrain = async () => {
        console.log("trying to load train");
        const urlParams = new URLSearchParams(window.location.search);
        const trainId: number | null = Number(urlParams.get("trainId"));

        id.current = trainId;

        if (!trainId) {
            return;
        }
        setIsNewTrain(false);

        const [status, payload] = await Requester.getTrain(trainId);
        if (status === ServerStatus.error) {
            alert("error");
            return;
        } else if (status === ServerStatus.unauth) {
            alert("wrong password!");
            navigate("/enter");
            return;
        } else if (status === ServerStatus.not_found) {
            alert("Не смог найти слово по id в адерсной строке");
            navigate("/words");
            return;
        }
        if (payload) {
            id.current = payload.id;
            setTrain(payload);
        }
    }

    const loadAutoTasksTempaltesTypes = async () => {
        const [status, payload] = await Requester.getAutotasksTemlatesTypes();
        checkServerStatusAndDo(status, payload, navigate, () => {
            payload && setAutotasksTemplates(payload);
        });
    }

    React.useEffect(() => {
        tryToLoadTrain();
        loadAutoTasksTempaltesTypes();
    }, [])


    const deleteTemplate = async (id: number) => {
        const [status, payload] = await Requester.deleteTrainAutoTaskTemplate(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            train && setTrain({ ...train, autoTasksTemplatesList: train?.autoTasksTemplatesList.filter((e: AutoTaskTemplate) => e.id != id) });
            alrt(`Шаблон удалён`);
        });
    }

    const testTemplate = async (text: string, templateType: AutoTaskTemplateEnum) => {
        console.log(templateType, text);
        const [status, payload] = await Requester.testTemplate(text, templateType);
        if (status === ServerStatus.note) {
            alert(payload);
            return;
        }
        checkServerStatusAndDo(status, payload, navigate, () => { });
        alert("ok");
    }

    const saveTrain = async () => {
        if (!train.title) {
            alert("Название необходимо");
            return;
        }
        const [status, payload] = await Requester.saveTrain(train);
        checkServerStatusAndDo(status, payload, navigate, () => {
            setTrain(emptyConstructorTrain());
            tryToLoadTrain();
            alrt(`Тренировка сохранена`);
        });
    }

    const updateTrain = async () => {
        if (!train.title) {
            alert("Название необходимо");
            return;
        }
        const [status, payload] = await Requester.changeTrain(train);
        checkServerStatusAndDo(status, payload, navigate, () => {
            tryToLoadTrain();
            alrt(`Тренировка изменена`);
        });
    }

    const renderedTemplates = useMemo(() => train.autoTasksTemplatesList.map((e, i) => {
        return (
            <div key={i} className="template_constructor_area">
                <div className="template_constructor_selector_area">
                    <select value={e.templateType} onChange={sel => {
                        e.templateType = sel.target.value as unknown as AutoTaskTemplateEnum;
                        setTrain({ ...train })
                    }}>
                        {autotasksTemplates.map(r =>
                            <option key={r}>
                                {r}
                            </option>)
                        }
                    </select>
                    <button onClick={() => {
                        testTemplate(e.text, e.templateType)
                    }}>Проверить шаблон</button>
                    <button onClick={() => deleteTemplate(e.id)}>Удалить шаблон</button>
                    <input type="number" placeholder="Использовать" value={e.activeTasks} onChange={(t) => {
                        e.activeTasks = +t.target.value;
                        setTrain({ ...train });
                        console.log(train)
                    }} />
                </div>
                <div className="template_constructor_textarea">
                    <textarea value={e.text} onChange={ch => {
                        e.text = ch.target.value;
                        setTrain({ ...train });
                    }} />
                </div>
            </div>
        )
    }
    ), [train]);

    return (
        <>
            <Alert message={alertMessage} />
            <div className="main_container">

                <button onClick={() => navigate("/trains")} className="constructor_button">Назад</button>
                <button className="constructor_button" onClick={() => isNewTrain ? saveTrain() : updateTrain()}>Сохранить тренировку</button>

                <div className="load_title_and_button">
                    <div className="el_w50">
                        Название тренировки
                    </div>

                    <div className="el_w50">
                        <input className="constructor_input el_w100" id="kz_input" placeholder="title" type="text" value={train.title}
                            onChange={e => setTrain({ ...train, title: e.target.value })} />
                    </div>
                </div>

                <div className="words_list">
                    Список шаблонов: <button onClick={() => {
                        train.autoTasksTemplatesList.push({ text: "", templateType: AutoTaskTemplateEnum.CLASSIFY_SELECT } as AutoTaskTemplate)
                        setTrain({ ...train })
                    }}>+</button>
                    {renderedTemplates}
                </div>

            </div>
        </>
    );
}
