import Alert from "js/Alert";
import { Requester, ServerStatus } from "js/Requester";
import { ConstructorChapter, ConstructorTopic, ConstructorTrain, emptyConstructorChapter, Word } from "js/types";
import React, { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import soundIco from "static/icons/sound.svg";
import nosoundIco from "static/icons/nosound.svg";
import plusIco from "static/icons/plus.svg";
import minusIco from "static/icons/minus.svg";
import { renderedTopicsList } from "./jsx/renderedLists";
import { checkServerStatusAndDo } from "./jsx/common";

export default function ChapterConstructor() {

    const navigate = useNavigate();

    const [isNewChapter, setIsNewChapter] = React.useState<boolean>(true);

    const [freeTopics, setFreeTopics] = React.useState<ConstructorTopic[]>([]);

    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const alrt = (msg: string) => {
        setAlertMessage(msg);
        setTimeout(() => setAlertMessage(""), 2500);
    }

    const [filterText, setFilterText] = React.useState<string>("");

    const [chapter, setChapter] = React.useState<ConstructorChapter>(emptyConstructorChapter());

    const id = useRef<number>(-1);

    const tryToLoadChapter = async () => {
        console.log("trying to load chapter");
        const urlParams = new URLSearchParams(window.location.search);
        const chapterId: number | null = Number(urlParams.get("chapterId"));

        id.current = chapterId;

        if (!chapterId) {
            return;
        }
        setIsNewChapter(false);

        const [status, payload] = await Requester.getChapter(chapterId);
        if (status === ServerStatus.error) {
            alert("error");
            return;
        } else if (status === ServerStatus.unauth) {
            alert("wrong password!");
            navigate("/enter");
            return;
        } else if (status === ServerStatus.not_found) {
            alert("Не смог найти слово по id в адерсной строке");
            navigate("/words");
            return;
        }
        if (payload) {
            id.current = payload.id;
            setChapter(payload);
            console.log(payload);
        }
    }

    const loadFreeTopics = async () => {
        const [status, payload] = await Requester.getAllFreeTopics();
        checkServerStatusAndDo(status, payload, navigate, () => {
            payload && setFreeTopics(payload);
        });
    }

    React.useEffect(() => {
        tryToLoadChapter();
        loadFreeTopics();
    }, [])


    const saveChapter = async () => {
        if (!chapter.title) {
            alert("Название необходимо");
            return;
        }
        const [status, payload] = await Requester.saveChapter(chapter);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt(`Глава сохранена`);
            setChapter(emptyConstructorChapter());
            loadFreeTopics();
        });
    }

    const updateChapter = async () => {
        if (!chapter.title) {
            alert("Название необходимо");
            return;
        }
        const [status, payload] = await Requester.changeChapter(chapter);
        if (status === ServerStatus.error) {
            alert("error");
            return;
        }
        if (status === ServerStatus.unauth) {
            alert("unauth");
            return;
        }
        if (payload) {
            console.log(payload);
            setAlertMessage("Глава изменена");
            setTimeout(() => setAlertMessage(""), 2500);
        }
    }

    const moveTopic = async (id: number, dir: string) => {
        const [status, payload] =
            dir === "dec"
                ? await Requester.moveTopicDecrease(id)
                : await Requester.moveTopicIncrease(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            tryToLoadChapter();
            loadFreeTopics();
            alrt(`Тема перемещена`);
        });
    }

    const toggleTopicPublish = async (e: ConstructorTopic) => {
        e.publish = !e.publish;
        const [status, payload] = await Requester.changeTopic(e);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt(`Тренировка ${e.publish ? "опубликована" : "скрыта"}`);
            loadFreeTopics();
            tryToLoadChapter();
        });
    }
    
    const deleteTopic = async (id: number) => {
        const [status, payload] = await Requester.deleteTopic(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            setFreeTopics(freeTopics.filter((e: ConstructorTopic) => e.id !== id));
            setChapter({...chapter, topicsList: chapter.topicsList.filter((e: ConstructorTopic) => e.id !== id)});
            alrt(`Тема удалена`);
        })
    }

    const renderedChaptersTopicsList = useMemo(() =>
        renderedTopicsList(
            chapter.topicsList,
            "",
            moveTopic,
            toggleTopicPublish,
            null,
            navigate,
            (e: ConstructorTopic) =>
                    <img src={minusIco} onClick={() => {
                        chapter.topicsList.splice(chapter.topicsList.indexOf(e), 1);
                        freeTopics.push(e);
                        setChapter({...chapter});
                    }} />
        ),
        [chapter, freeTopics]);

    const renderedFreeTopics = useMemo(() =>
        renderedTopicsList(
            freeTopics,
            "",
            moveTopic,
            toggleTopicPublish,
            null,
            navigate,
            (e: ConstructorTopic) =>
                    <img src={plusIco} onClick={() => {
                        chapter.topicsList.push(e);
                        freeTopics.splice(freeTopics.indexOf(e), 1);
                        setChapter({...chapter});
                    }} />
        ),
        [chapter, freeTopics]);


    return (
        <>
            <Alert message={alertMessage} />
            <div className="main_container">

                <button onClick={() => navigate("/chapters")} className="constructor_button">Назад</button>
                <button className="constructor_button" onClick={() => isNewChapter ? saveChapter() : updateChapter()}>Сохранить главу</button>

                <div className="load_title_and_button">
                    <div className="el_w50">
                        Название главы
                    </div>

                    <div className="el_w50">
                        <input className="constructor_input el_w100" id="kz_input" placeholder="title" type="text" value={chapter.title}
                            onChange={e => setChapter({ ...chapter, title: e.target.value })} />
                    </div>
                </div>

                <div className="words_list">
                    Темы в этой главе:
                    {renderedChaptersTopicsList}
                </div>

                <div className="words_list">
                    Бесхозные темы:
                    {renderedFreeTopics}
                </div>

            </div>
        </>
    );

}
