import { Cookier } from "./Cookier";
import { ConstructorChapter, LoginResponse, AutoTaskTemplateEnum, ConstructorTopic, ConstructorTrain, Word, WordsRoom, WordsChapter, Sound } from "./types";

//const baseUrl: string = "http://192.168.0.18:9090";
//const static_url = "http://192.168.1.114/static_files/";

const baseUrl: string = "https://admin.lingolope.com";
const static_url = "https://admin.lingolope.com/static_files/";

const defaultHeaders = () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return headers;
}

const defaultHeadersWithToken = () => {
    let headers = defaultHeaders();
    headers.append("Authorization", `Bearer ${Cookier.getToken()}`);
    return headers;
}

export enum ServerStatus {
    ok,
    note,
    unacceptable,
    error,
    unauth,
    not_found
}

export const Requester = {
    login: async (username: string, password: string): Promise<[ServerStatus, LoginResponse | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify({ username, password })
        };
        const url = `${baseUrl}/api/public/enter/get_token`;

        try {
            const response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as LoginResponse];
            } else if (response.status === 401) {
                return [ServerStatus.unauth, await response.json() as unknown as LoginResponse];
            } else
                return [ServerStatus.error, null];
        } catch {
            return [ServerStatus.error, null];
        }
    },
    checkLogin: async (): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/enter/check`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            }
            else throw Error("Non 200 response code");
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
            }
            return [ServerStatus.error, null];
        }
        return [ServerStatus.error, null];
    },
    getAllWords: async (): Promise<[ServerStatus, Word[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/lexis/get_all`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as Word[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    saveWord: async (kz: string[], ru: string[], image: string, sound: string): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
            body: JSON.stringify({ wordKz: kz, wordRu: ru, image, sound })
        };
        const url = `${baseUrl}/api/private/lexis/save`;

        try {
            const response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else if (response.status === 403) {
                return [ServerStatus.unauth, await response.text() as unknown as boolean];
            } else
                return [ServerStatus.error, null];
        } catch {
            return [ServerStatus.error, null];
        }
    },
    changeWord: async (id: number, kz: string[], ru: string[], image: string, sound: string): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
            body: JSON.stringify({ id, wordKz: kz, wordRu: ru, image, sound })
        };
        const url = `${baseUrl}/api/private/words/change`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.not_found, null];
                }
            }
            return [ServerStatus.error, null];
        }

    },
    deleteWord: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'DELETE',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/words/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getWord: async (id: number): Promise<[ServerStatus, Word | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/words/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as Word];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.not_found, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getSound: async (id: number): Promise<[ServerStatus, Sound | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/sounds/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as Sound];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.not_found, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getAllSounds: async (): Promise<[ServerStatus, Sound[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/sounds/get_all`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as Sound[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    saveSound: async (sound: Sound): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
            body: JSON.stringify({ ...sound })
        };
        const url = `${baseUrl}/api/private/sounds/save`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.not_found, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    changeSound: async (sound: Sound): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
            body: JSON.stringify({ ...sound })
        };
        const url = `${baseUrl}/api/private/sounds/change`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.not_found, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    deleteSound: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'DELETE',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/sounds/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
                if (response.status === 406) {
                    return [ServerStatus.unacceptable, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getAllWordsChapters: async (): Promise<[ServerStatus, WordsChapter[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/words_chapters/get_all`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as WordsChapter[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    saveWordsChapter: async (chapter: WordsChapter): Promise<[ServerStatus, boolean | null]> => {
        console.log(chapter);
        console.log(JSON.stringify(chapter));
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
            body: JSON.stringify({ ...chapter })
        };
        const url = `${baseUrl}/api/private/words_chapters/save`;

        try {
            const response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else if (response.status === 403) {
                return [ServerStatus.unauth, await response.text() as unknown as boolean];
            } else
                return [ServerStatus.error, null];
        } catch {
            return [ServerStatus.error, null];
        }
    },
    changeWordsChapter: async (chapter: WordsChapter): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
            body: JSON.stringify({ ...chapter })
        };
        const url = `${baseUrl}/api/private/words_chapters/change`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.not_found, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    deleteWordsChapter: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'DELETE',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/words_chapters/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
                if (response.status === 406) {
                    return [ServerStatus.unacceptable, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getWordsChapter: async (id: number): Promise<[ServerStatus, WordsChapter | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/words_chapters/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as WordsChapter];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.not_found, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    moveWordsChapterIncrease: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/words_chapters/${id}/inc`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    moveWordsChapterDecrease: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/words_chapter/${id}/dec`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getAllWordsRooms: async (): Promise<[ServerStatus, WordsRoom[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/words_rooms/get_all`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as WordsRoom[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getAllFreeWordsRooms: async (): Promise<[ServerStatus, WordsRoom[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/words_rooms/free/get_all`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as WordsRoom[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getAllHiddenWordsRooms: async (): Promise<[ServerStatus, WordsRoom[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/words_rooms/hidden/get_all`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as WordsRoom[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getWordsRoom: async (id: number): Promise<[ServerStatus, WordsRoom | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/words_rooms/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as WordsRoom];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.not_found, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    saveWordsRoom: async (room: WordsRoom): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
            body: JSON.stringify(room)
        };
        const url = `${baseUrl}/api/private/words_rooms/save`;

        try {
            const response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else if (response.status === 403) {
                return [ServerStatus.unauth, await response.text() as unknown as boolean];
            } else
                return [ServerStatus.error, null];
        } catch {
            return [ServerStatus.error, null];
        }
    },
    changeWordsRoom: async (room: WordsRoom): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
            body: JSON.stringify(room)
        };
        const url = `${baseUrl}/api/private/words_rooms/change`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.not_found, null];
                }
            }
            return [ServerStatus.error, null];
        }

    },
    deleteWordsRoom: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'DELETE',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/words_rooms/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    moveWordsRoomIncrease: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/words_rooms/${id}/inc`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    moveWordsRoomDecrease: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/words_rooms/${id}/dec`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getAllChapters: async (): Promise<[ServerStatus, ConstructorChapter[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/chapters/get_all`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as ConstructorChapter[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    saveChapter: async (chapter: ConstructorChapter): Promise<[ServerStatus, boolean | null]> => {
        console.log(chapter);
        console.log(JSON.stringify(chapter));
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
            body: JSON.stringify({ ...chapter })
        };
        const url = `${baseUrl}/api/private/chapters/save`;

        try {
            const response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else if (response.status === 403) {
                return [ServerStatus.unauth, await response.text() as unknown as boolean];
            } else
                return [ServerStatus.error, null];
        } catch {
            return [ServerStatus.error, null];
        }
    },
    changeChapter: async (chapter: ConstructorChapter): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
            body: JSON.stringify({ ...chapter })
        };
        const url = `${baseUrl}/api/private/chapters/change`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.not_found, null];
                }
            }
            return [ServerStatus.error, null];
        }

    },
    deleteChapter: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'DELETE',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/chapters/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
                if (response.status === 406) {
                    return [ServerStatus.unacceptable, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getChapter: async (id: number): Promise<[ServerStatus, ConstructorChapter | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/chapters/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as ConstructorChapter];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.not_found, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    moveChapterIncrease: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/chapters/${id}/inc`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    moveChapterDecrease: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/chapters/${id}/dec`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getAllTopics: async (): Promise<[ServerStatus, ConstructorTopic[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/topics/get_all`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as ConstructorTopic[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getAllFreeTopics: async (): Promise<[ServerStatus, ConstructorTopic[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/topics/free/get_all`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as ConstructorTopic[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    saveTopic: async (topic: ConstructorTopic): Promise<[ServerStatus, boolean | null]> => {
        console.log(topic);
        console.log(JSON.stringify(topic));
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
            body: JSON.stringify({ ...topic })
        };
        const url = `${baseUrl}/api/private/topics/save`;

        try {
            const response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else if (response.status === 403) {
                return [ServerStatus.unauth, await response.text() as unknown as boolean];
            } else
                return [ServerStatus.error, null];
        } catch {
            return [ServerStatus.error, null];
        }
    },
    changeTopic: async (topic: ConstructorTopic): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
            body: JSON.stringify({ ...topic })
        };
        const url = `${baseUrl}/api/private/topics/change`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.not_found, null];
                }
            }
            return [ServerStatus.error, null];
        }

    },
    deleteTopic: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'DELETE',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/topics/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
                if (response.status === 406) {
                    return [ServerStatus.unacceptable, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getTopic: async (id: number): Promise<[ServerStatus, ConstructorTopic | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/topics/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as ConstructorTopic];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.not_found, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    moveTopicIncrease: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/topics/${id}/inc`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    moveTopicDecrease: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/topics/${id}/dec`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getAllTrains: async (): Promise<[ServerStatus, ConstructorTrain[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/trains/get_all`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as ConstructorTrain[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getAllFreeTrains: async (): Promise<[ServerStatus, ConstructorTrain[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/trains/free/get_all`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as ConstructorTrain[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    saveTrain: async (train: ConstructorTrain): Promise<[ServerStatus, boolean | null]> => {
        console.log(train);
        console.log(JSON.stringify(train));
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
            body: JSON.stringify({ ...train })
        };
        const url = `${baseUrl}/api/private/trains/save`;

        try {
            const response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else if (response.status === 403) {
                return [ServerStatus.unauth, await response.text() as unknown as boolean];
            } else
                return [ServerStatus.error, null];
        } catch {
            return [ServerStatus.error, null];
        }
    },
    changeTrain: async (train: ConstructorTrain): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
            body: JSON.stringify({ ...train })
        };
        const url = `${baseUrl}/api/private/trains/change`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.not_found, null];
                }
            }
            return [ServerStatus.error, null];
        }

    },
    deleteTrain: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'DELETE',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/trains/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
                if (response.status === 406) {
                    return [ServerStatus.unacceptable, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    deleteTrainAutoTaskTemplate: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'DELETE',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/trains/autotemplate/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
                if (response.status === 406) {
                    return [ServerStatus.unacceptable, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getTrain: async (id: number): Promise<[ServerStatus, ConstructorTrain | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/trains/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as ConstructorTrain];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.not_found, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    moveTrainIncrease: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/trains/${id}/inc`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    moveTrainDecrease: async (id: number): Promise<[ServerStatus, boolean | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/trains/${id}/dec`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text() as unknown as boolean];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    getAutotasksTemlatesTypes: async (): Promise<[ServerStatus, string[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
        };
        const url = `${baseUrl}/api/private/trains/get_autotasks_temlates`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as string[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 404) {
                    return [ServerStatus.error, null];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    testTemplate: async (text: string, type: AutoTaskTemplateEnum): Promise<[ServerStatus, string | null]> => {
        console.log("HTTP");
        console.log(type);
        const requestOptions = {
            method: 'POST',
            headers: defaultHeadersWithToken(),
            body: JSON.stringify({ text, templateType: type })
        };
        const url = `${baseUrl}/api/private/trains/check_template`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, null];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            if (response) {
                if (response.status === 403) {
                    return [ServerStatus.unauth, null];
                }
                if (response.status === 409) {
                    return [ServerStatus.note, await response.text() as unknown as string];
                }
            }
            return [ServerStatus.error, null];
        }
    },
    static_url

}
