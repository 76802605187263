import Alert from "js/Alert";
import { Requester, ServerStatus } from "js/Requester";
import { WordsChapter } from "js/types";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { checkServerStatusAndDo } from "./jsx/common";
import { renderedWordsChaptersList } from "./jsx/renderedLists";

export default function WordsChapterPool() {

    const navigate = useNavigate();

    const [allWordsChapters, setAllWordsChapters] = React.useState<WordsChapter[]>([]);

    const [wordsWordsChapterFilter, setWordsChapterFilter] = React.useState<string>("");

    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const alrt = (msg: string) => {
        setAlertMessage(msg);
        setTimeout(() => setAlertMessage(""), 2500);
    }

    const loadWordsChapters = async () => {
        const [status, payload] = await Requester.getAllWordsChapters();
        checkServerStatusAndDo(status, payload, navigate, () => {
            payload && setAllWordsChapters(payload);
        });
    }

    React.useEffect(() => {
        loadWordsChapters();
    }, [])

    const deleteWordsChapter = async (id: number) => {
        const [status, payload] = await Requester.deleteWordsChapter(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            setAllWordsChapters(allWordsChapters.filter((e: WordsChapter) => e.id !== id));
            alrt(`Глава удалена`);
        });
    }

    const moveWordsChapter = async (id: number, dir: string) => {
        const [status, payload] =
            dir === "dec"
                ? await Requester.moveWordsChapterDecrease(id)
                : await Requester.moveWordsChapterIncrease(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            loadWordsChapters();
            alrt(`Глава пермещена`);
        });
    }

    const togglePublish = async (e: WordsChapter) => {
        e.publish = !e.publish;
        const [status, payload] = await Requester.changeWordsChapter(e);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt(`Глава ${e.publish ? "опубликована" : "скрыта"}`);
            loadWordsChapters();
        });
    }

    const renderedWordsChapters = useMemo(() =>
        renderedWordsChaptersList(
            allWordsChapters,
            wordsWordsChapterFilter,
            moveWordsChapter,
            togglePublish,
            deleteWordsChapter,
            navigate),
        [wordsWordsChapterFilter, allWordsChapters]);

    return (
        <div className="main_container">
            <Alert message={alertMessage} />
            <input className="constructor_input" type="text" placeholder="Поиск" value={wordsWordsChapterFilter} onChange={(e) => setWordsChapterFilter(e.target.value)} />
            <div className="words_list">
                {renderedWordsChapters}
            </div>
        </div>
    );
}
