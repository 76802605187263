import Alert from "js/Alert";
import { Requester, ServerStatus } from "js/Requester";
import { ConstructorTopic } from "js/types";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { renderedTopicsList } from "./jsx/renderedLists";
import { checkServerStatusAndDo, toggleTopicPublish } from "./jsx/common";

export default function TopicPool() {

    const navigate = useNavigate();

    const [allTopics, setAllTopics] = React.useState<ConstructorTopic[]>([]);

    const [topicFilter, setTopicFilter] = React.useState<string>("");

    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const alrt = (msg: string) => {
        setAlertMessage(msg);
        setTimeout(() => setAlertMessage(""), 2500);
    }

    const loadTopics = async () => {
        const [status, payload] = await Requester.getAllTopics();
        checkServerStatusAndDo(status, payload, navigate, () => {
            payload && setAllTopics(payload);
        })
    }

    React.useEffect(() => {
        loadTopics();
    }, [])

    const deleteTopic = async (id: number) => {
        const [status, payload] = await Requester.deleteTopic(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            setAllTopics(allTopics.filter((e: ConstructorTopic) => e.id !== id));
            alrt(`Тема удалена`);
        })
    }


    const togglePublish = async (e: ConstructorTopic) => {
        e.publish = !e.publish;
        const [status, payload] = await Requester.changeTopic(e);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt(`Тренировка ${e.publish ? "опубликована" : "скрыта"}`);
            loadTopics();
        });
    }

    const renderedTopics = useMemo(() =>
        renderedTopicsList(
            allTopics,
            topicFilter,
            null,
            togglePublish,
            deleteTopic,
            navigate,
            null),
        [allTopics, topicFilter]);

    return (
        <div className="main_container">
            <Alert message={alertMessage} />
            <input className="constructor_input" type="text" placeholder="filter words" value={topicFilter} onChange={(e) => setTopicFilter(e.target.value)} />
            <div className="words_list">
                {renderedTopics}
            </div>
        </div>
    );
}
