import { ConstructorChapter, ConstructorTopic, ConstructorTrain, WordsChapter, WordsRoom } from "js/types";

import editIco from "static/icons/edit.svg";
import removeIco from "static/icons/remove.svg";
import publicIco from "static/icons/public.svg";
import privateIco from "static/icons/private.svg";

export const renderedChaptersList = (
    allChapters: ConstructorChapter[],
    filter: string,
    move: any,
    togglePublish: any,
    deleteChapter: any,
    navigate: any) =>

    allChapters.filter((e: ConstructorChapter) => (e.title).includes(filter))
        .map((e: ConstructorChapter) =>
            <div key={e.id} className="word fl_evenly">
                <div className="word_section word_button fl_evenly el_w10" >
                    <button onClick={() => move(e.id, "dec")}>^ </button>
                    <button onClick={() => move(e.id, "inc")}> v </button>
                </div>
                <div className="word_section fl_center el_w30" >
                    <div className="word_section_val" > {e.title} </div>
                </div>
                <div className="word_section fl_center el_w30" >
                    <div className="word_section_val" > {e.title} </div>
                </div>
                {
                    e.publish &&
                    <div className="word_section word_button fl_center el_w20" >
                        <img src={publicIco} onClick={() => togglePublish(e)
                        } />
                    </div>
                }
                {
                    !e.publish &&
                    <div className="word_section word_button fl_center el_w20" >
                        <img src={privateIco} onClick={() => togglePublish(e)
                        } />
                    </div>
                }
                <div className="word_section word_button fl_evenly el_w10" >
                    <img src={editIco} onClick={() => navigate(`/chapters/edit?chapterId=${e.id}`)} />
                    <img src={removeIco} onClick={() => deleteChapter(e.id)} />
                </div>
            </div>);

export const renderedTopicsList = (
    allTopics: ConstructorTopic[],
    filter: string,
    move: any,
    togglePublish: any,
    deleteTopic: any,
    navigate: any,
    extra: any) =>

    allTopics
        .filter((e: ConstructorTopic) => (e.title).includes(filter))
        .map((e: ConstructorTopic) =>
            <div key={e.id} className="word fl_evenly">
                {extra &&
                    <div className="word_section word_button fl_evenly el_w10">
                        <button onClick={() => move(e.id, "dec")}>^</button>
                        <button onClick={() => move(e.id, "inc")}>v</button>
                    </div>
                }
                <div className="word_section fl_center el_w30">
                    <div className="word_section_val">{e.title}</div>
                </div>
                <div className="word_section fl_center el_w30">
                    <div className="word_section_val">#{e.position}</div>
                </div>
                {
                    e.publish &&
                    <div className="word_section word_button fl_center el_w20" >
                        <img src={publicIco} onClick={() => togglePublish(e)
                        } />
                    </div>
                }
                {
                    !e.publish &&
                    <div className="word_section word_button fl_center el_w20" >
                        <img src={privateIco} onClick={() => togglePublish(e)
                        } />
                    </div>
                }
                <div className="word_section word_button fl_evenly el_w10">
                    {extra && extra(e)}
                    <img src={editIco} onClick={() => navigate(`/topics/edit?topicId=${e.id}`)} />
                    {deleteTopic !== null && <img src={removeIco} onClick={() => deleteTopic(e.id)} />}
                </div>
            </div>);

export const renderedTrainsList = (
    allTrains: ConstructorTrain[],
    filter: string,
    move: any,
    togglePublish: any,
    deleteTrain: any,
    navigate: any,
    extra: any) =>
    allTrains
        .filter((e: ConstructorTrain) => (e.title).includes(filter))
        .map((e: ConstructorTrain) =>
            <div key={e.id} className="word fl_evenly">
                {extra &&
                    <div className="word_section word_button fl_evenly el_w10">
                        <button onClick={() => move(e.id, "dec")}>^</button>
                        <button onClick={() => move(e.id, "inc")}>v</button>
                    </div>
                }
                <div className="word_section fl_center el_w30">
                    <div className="word_section_val">{e.title}</div>
                </div>
                <div className="word_section fl_center el_w30">
                    <div className="word_section_val">{e.title}</div>
                </div>
                {e.publish &&
                    <div className="word_section word_button fl_center el_w20">
                        <img src={publicIco} onClick={() => togglePublish(e)} />
                    </div>
                }
                {!e.publish &&
                    <div className="word_section word_button fl_center el_w20">
                        <img src={privateIco} onClick={() => togglePublish(e)} />
                    </div>
                }
                <div className="word_section word_button fl_evenly el_w10">
                    {extra && extra(e)}
                    <img src={editIco} onClick={() => navigate(`/trains/edit?trainId=${e.id}`)} />
                    {deleteTrain !== null && <img src={removeIco} onClick={() => deleteTrain(e.id)} />}
                </div>
            </div>
        );

export const renderedWordsRoomsList = (
    allWordsRooms: WordsRoom[],
    filter: string,
    move: any,
    togglePublish: any,
    deleteWordsRoom: any,
    navigate: any,
    extra: any) =>

    allWordsRooms
        .filter((e: WordsRoom) => (e.title).includes(filter))
        .map((e: WordsRoom) =>
            <div key={e.id} className="word fl_evenly">
                {extra &&
                    <div className="word_section word_button fl_evenly el_w10">
                        <button onClick={() => move(e.id, "dec")}>^</button>
                        <button onClick={() => move(e.id, "inc")}>v</button>
                    </div>
                }
                <div className="word_section fl_center el_w30">
                    <div className="word_section_val">{e.title}</div>
                </div>
                <div className="word_section fl_center el_w30">
                    <div className="word_section_val">{e.wordList.length} слов</div>
                </div>
                {e.publish &&
                    <div className="word_section word_button fl_center el_w20">
                        <img src={publicIco} onClick={() => togglePublish(e)}/>
                    </div>
                }
                {!e.publish &&
                    <div className="word_section word_button fl_center el_w20">
                        <img src={privateIco} onClick={() => togglePublish(e)} />
                    </div>
                }
                <div className="word_section word_button fl_evenly el_w10">
                    {extra && extra(e)}
                    <img src={editIco} onClick={() => navigate(`/words_rooms/edit?wordsRoomId=${e.id}`)} />
                    <img src={removeIco} onClick={() => {
                        const ans: boolean = window.confirm(`Комната содержит ${e.wordList.length} слов. Уверены, что хотите удалить?`);
                        if (ans) deleteWordsRoom(e.id);
                    }} />
                </div>
            </div>
        );



export const renderedWordsChaptersList = (
    allChapters: WordsChapter[],
    filter: string,
    move: any,
    togglePublish: any,
    deleteWordsChapter: any,
    navigate: any) =>

    allChapters.filter((e: WordsChapter) => (e.title).includes(filter))
        .map((e: WordsChapter) =>
            <div key={e.id} className="word fl_evenly">
                <div className="word_section word_button fl_evenly el_w10" >
                    <button onClick={() => move(e.id, "dec")}>^ </button>
                    <button onClick={() => move(e.id, "inc")}> v </button>
                </div>
                <div className="word_section fl_center el_w30" >
                    <div className="word_section_val" > {e.title} </div>
                </div>
                <div className="word_section fl_center el_w30" >
                    <div className="word_section_val" > {e.title} </div>
                </div>
                {
                    e.publish &&
                    <div className="word_section word_button fl_center el_w20" >
                        <img src={publicIco} onClick={() => togglePublish(e)
                        } />
                    </div>
                }
                {
                    !e.publish &&
                    <div className="word_section word_button fl_center el_w20" >
                        <img src={privateIco} onClick={() => togglePublish(e)
                        } />
                    </div>
                }
                <div className="word_section word_button fl_evenly el_w10" >
                    <img src={editIco} onClick={() => navigate(`/words_chapters/edit?wordsChapterId=${e.id}`)} />
                    <img src={removeIco} onClick={() => deleteWordsChapter(e.id)} />
                </div>
            </div>);
