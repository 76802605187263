import { Requester, ServerStatus } from "js/Requester";
import { Word } from "js/types";
import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import editIco from "static/icons/edit.svg";
import removeIco from "static/icons/remove.svg";
import soundIco from "static/icons/sound.svg";
import nosoundIco from "static/icons/nosound.svg";
import Alert from "js/Alert";

export default function WordPool() {

    const navigate = useNavigate();

    const [allWords, setAllWords] = React.useState<Word[]>([]);

    const [wordFilter, setWordFilter] = React.useState<string>("");

    const [alertMessage, setAlertMessage] = React.useState<string>("");

    const loadWords = async () => {
        const [status, payload] = await Requester.getAllWords();
        if (status === ServerStatus.error) {
            alert("error");
            return;
        } else if (status === ServerStatus.unauth) {
            alert("wrong password!");
            navigate("/enter");
            return;
        }
        if (payload) {
            setAllWords(payload);
        }
    }

    React.useEffect(() => {
        loadWords();
    }, [])

    const deleteWord = async (id: number) => {
        const [status, payload] = await Requester.deleteWord(id);
        if (status === ServerStatus.error) {
            alert("error");
            return;
        } else if (status === ServerStatus.unauth) {
            alert("wrong password!");
            navigate("/enter");
            return;
        }
        if (payload) {
            setAllWords(allWords.filter((e: Word) => e.id !== id));
            setAlertMessage("Слово удалено");
            setTimeout(() => setAlertMessage(""), 2500);
        }
    }

    const renderedWords = allWords
        .filter((e: Word) => (e.kazakh.reduce((tot, val) => tot + val, "") + e.russian.reduce((tot, val) => tot + val, "")).includes(wordFilter))
        .map((e: Word) =>
            <div key={e.id} className="word fl_evenly">
                <div className="word_section fl_center el_w30">
                    <div className="word_section_val">{e.kazakh[0]}</div>
                </div>
                <div className="word_section fl_center el_w30">
                    <div className="word_section_val">{e.russian[0]}</div>
                </div>
                <div className="word_section fl_center el_w20">
                    <img src={Requester.static_url + "words/images/" + e.image + ".jpg"} />
                </div>
                <div className="word_section word_button fl_center el_w10">
                    {e.sound &&
                        <>
                            <img src={soundIco} onClick={() => {
                                const audio = new Audio(Requester.static_url + "words/sounds/" + e.sound + ".mp3");
                                audio.play();
                            }} />
                        </>
                    }
                    {!e.sound &&
                        <img src={nosoundIco} />
                    }
                </div>
                <div className="word_section word_button fl_evenly el_w10">
                    <img src={editIco} onClick={() => navigate(`/words/edit?wordId=${e.id}`)} />
                    <img src={removeIco} onClick={() => deleteWord(e.id)} />
                </div>
            </div>
        );

    return (
        <div className="main_container">
            <Alert message={alertMessage} />
            <button onClick={() => navigate("/")} className="constructor_button">На главную</button>
            <button onClick={() => navigate("/words/add")} className="constructor_button">Добавить слово</button>
            <input className="constructor_input" type="text" placeholder="filter words" value={wordFilter} onChange={(e) => setWordFilter(e.target.value)} />
            <div className="words_list">
                {renderedWords}
            </div>
        </div>
    );
}
