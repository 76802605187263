import Alert from "js/Alert";
import { focusOnElement } from "js/helper";
import { Requester, ServerStatus } from "js/Requester";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";


type CanvasFocus = {
    x: number,
    y: number,
    w: number,
    h: number
}

export default function WordConstructor() {

    const navigate = useNavigate();

    const [wordKz, setWordKz] = React.useState<string[]>([]);
    const [wordRu, setWordRu] = React.useState<string[]>([]);

    const [newImageResult, setNewImageResult] = React.useState<string>('');
    const [sound, setSound] = React.useState<string>('');

    const canvas = useRef<HTMLCanvasElement>(null);

    const [canvasRect, setCanvasRect] = React.useState<CanvasFocus>({ x: 100, y: 100, w: 400, h: 400 });

    const [finalImage, setFinalImage] = React.useState<string>("");

    const [alertMessage, setAlertMessage] = React.useState<string>("");

    const id = useRef<number>(-1);
    const oldImage = useRef<string>("");
    const oldSound = useRef<string>("");
    const [isNewWord, setIsNewWord] = React.useState<boolean>(true);

    const loadImageFromDisk = (e: any) => {
        let tgt = e.target || window?.event?.srcElement,
            files = tgt.files;
        if (FileReader && files && files.length) {
            let fr = new FileReader();
            fr.onload = () => {
                setNewImageResult(fr.result as string);
                drawRect();
            }
            fr.readAsDataURL(files[0]);
        }
    }

    const zoomScroll = (e: any) => {
        const plus = e.deltaY < 0 ? 10 : -10;
        if (plus > 0 && (canvasRect.h + plus > 1000 || canvasRect.w + plus > 1000)) return;
        if (plus < 0 && (canvasRect.h + plus < 400 || canvasRect.w + plus < 400)) return;
        setCanvasRect({ ...canvasRect, w: canvasRect.w + plus, h: canvasRect.h + plus });
    }

    const zoom = (plus: number) => {
        if (plus > 0 && (canvasRect.h + plus > 1000 || canvasRect.w + plus > 1000)) return;
        if (plus < 0 && (canvasRect.h + plus < 400 || canvasRect.w + plus < 400)) return;
        setCanvasRect({ ...canvasRect, w: canvasRect.w + plus, h: canvasRect.h + plus });
    }

    const move = (e: any) => {
        const rect = canvas.current?.getBoundingClientRect();
        if (!rect?.left || !rect?.top) return;
        const x = (e.clientX - rect.left) * 2
        const y = (e.clientY - rect.top) * 2
        console.log(canvasRect);
        setCanvasRect({ ...canvasRect, x: x - canvasRect.w / 2, y: y - canvasRect.h / 2 });
    }

    const drawRect = () => {
        const image = new Image;
        image.src = newImageResult;
        const ctx = canvas.current?.getContext("2d");
        //ctx?.drawImage(image, 0, 0, image.width, image.height);
        console.log(image.width + "+" + image.height);

        if (!canvas.current?.width || !canvas.current.height) return;
        let hRatio = canvas?.current?.width / image.width;
        let vRatio = canvas.current.height / image.height;
        let ratio = Math.min(hRatio, vRatio);
        let centerShift_x = (canvas.current.width - image.width * ratio) / 2;
        let centerShift_y = (canvas.current.height - image.height * ratio) / 2;
        ctx?.clearRect(0, 0, canvas.current.width, canvas.current.height);
        ctx?.drawImage(image, 0, 0, image.width, image.height,
            centerShift_x, centerShift_y, image.width * ratio, image.height * ratio);

        if (!ctx) return;
        ctx.strokeStyle = "#080E1E";
        ctx.lineWidth = 3;
        ctx?.strokeRect(canvasRect.x, canvasRect.y, canvasRect.w, canvasRect.h);
    }

    const tryToLoadWord = async () => {
        console.log("trying to load word");
        const urlParams = new URLSearchParams(window.location.search);
        const wordId: number | null = Number(urlParams.get("wordId"));

        id.current = wordId;

        if (!wordId) {
            return;
        }
        setIsNewWord(false);

        const [status, payload] = await Requester.getWord(wordId);
        if (status === ServerStatus.error) {
            alert("error");
            return;
        } else if (status === ServerStatus.unauth) {
            alert("wrong password!");
            navigate("/enter");
            return;
        } else if (status === ServerStatus.not_found) {
            alert("Не смог найти слово по id в адерсной строке");
            navigate("/words");
            return;
        }
        if (payload) {
            setWordKz(payload.kazakh);
            setWordRu(payload.russian);
            oldImage.current = payload.image;
            oldSound.current = payload.sound;
        }
    }

    React.useEffect(() => {
        tryToLoadWord();
        drawRect();
        const imageData = canvas.current?.getContext("2d")?.getImageData(canvasRect.x, canvasRect.y, canvasRect.w, canvasRect.h);
        const tmpCanvas = document.createElement("canvas");
        tmpCanvas.width = canvasRect.w;
        tmpCanvas.height = canvasRect.h;
        const tmpContext = tmpCanvas.getContext("2d");
        if (!imageData) return;
        console.log(imageData);
        tmpContext?.putImageData(imageData, 0, 0);
        setFinalImage(tmpCanvas.toDataURL() as unknown as string);
    }, [newImageResult, canvasRect])

    const loadSoundFromDisk = (e: any) => {
        let tgt = e.target || window?.event?.srcElement,
            files = tgt.files;
        if (FileReader && files && files.length) {

            let fr = new FileReader();
            fr.onload = () => setSound(fr.result as string);
            fr.readAsDataURL(files[0]);
        }
        const objUrl = URL.createObjectURL(e.target.files[0]);
        //(document.getElementById("sound_player") as HTMLAudioElement).src = objUrl;
    }

    const saveWord = async () => {
        setWordRu(wordRu.filter(e => e.trim() !== ""));
        setWordKz(wordKz.filter(e => e.trim() !== ""));
        console.log("saving word");
        console.log(wordKz);
        console.log(wordRu);
        console.log(newImageResult);
        console.log(sound);
        if (!wordRu || !wordKz) {
            alert("слово и перевод обязательны!");
            return;
        }
        const [status, payload] = await Requester.saveWord(wordKz, wordRu, finalImage, sound);
        if (status === ServerStatus.error) {
            alert("error");
            return;
        }
        if (status === ServerStatus.unauth) {
            alert("unauth");
            return;
        }
        if (payload) {
            console.log(payload);
            setAlertMessage("Слово сохранено");
            setTimeout(() => setAlertMessage(""), 2500);
            setWordKz([]);
            setWordRu([]);
            setNewImageResult("");
            setSound("");
        }

    }
    const updateWord = async () => {
        setWordRu(wordRu.filter(e => e.trim() !== ""));
        setWordKz(wordKz.filter(e => e.trim() !== ""));
        console.log("saving word");
        console.log(wordKz);
        console.log(wordRu);
        console.log(newImageResult);
        console.log(sound);
        if (!wordRu || !wordKz) {
            alert("слово и перевод обязательны!");
            return;
        }
        const [status, payload] = await Requester.changeWord(id.current, wordKz, wordRu, finalImage, sound);
        if (status === ServerStatus.error) {
            alert("error");
            return;
        }
        if (status === ServerStatus.unauth) {
            alert("unauth");
            return;
        }
        setAlertMessage("Слово изменено");
        setTimeout(() => setAlertMessage(""), 2500);

    }

    const OldImageTag = () => (<>
        {oldImage.current ?
            <div className="word_old_image">
                <img src={Requester.static_url + "/words/images/" + oldImage.current + ".jpg"} />
            </div>
            : "NO"
        }
    </>);

    const OldSoudTag = () => (<>
        {oldSound.current ?
            <>
                <div className="audio-play-button" onClick={() => {
                    const audio = new Audio(Requester.static_url + "words/sounds/" + oldSound.current + ".mp3");
                    audio.play();
                }}>
                    Play Audio
                </div>
                <audio id="audio-file" controls>
                    <source src={Requester.static_url + "words/sounds/" + oldSound.current + ".mp3"} type="audio/mp3" />
                    Your browser does not support the audio element.
                </audio>
            </>
            : "NO"
        }
    </>);
    
    const NewSoudTag = () => (<>
        {sound ?
            <>
                <div className="audio-play-button" onClick={() => {
                    const audio = new Audio(sound);
                    audio.play();
                }}>
                    Play Audio
                </div>
            </>
            : ""
        }
    </>);

    return (
        <>
            <Alert message={alertMessage} />
            <div className="main_container">

                <button onClick={() => navigate("/words")} className="constructor_button">Назад</button>
                <button className="constructor_button" onClick={() => isNewWord ? saveWord() : updateWord()}>Сохранить слово</button>

                <div className="load_title_and_button">
                    <div className="el_w50 synonims_list">
                        <div className="title_and_button">
                            Синонимы на казахском языке
                            <button onClick={() => { wordKz.push(""); setWordKz([...wordKz]) }}>+</button>
                        </div>
                        {wordKz.map((e, i) =>
                            <>
                                <div className="word_and_button">
                                    <input key={"kz" + i} className="constructor_input el_w90" id="kz_input" placeholder="kz" type="text" value={e}
                                        onChange={ch => {
                                            wordKz[i] = ch.target.value;
                                            setWordKz([...wordKz]);
                                        }} />
                                    <button onClick={() => { wordKz.splice(i, 1); setWordKz([...wordKz]) }}>-</button>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="el_w50 synonims_list">
                        <div className="title_and_button">
                            Синонимы на русском языке
                            <button onClick={() => { wordRu.push(""); setWordRu([...wordRu]) }}>+</button>
                        </div>
                        {wordRu.map((e, i) =>
                            <>
                                <div className="word_and_button">
                                    <input key={"ru" + i} className="constructor_input el_w90" id="kz_input" placeholder="ru" type="text" value={e}
                                        onChange={ch => {
                                            wordRu[i] = ch.target.value;
                                            setWordRu([...wordRu]);
                                        }} />
                                    <button onClick={() => { wordRu.splice(i, 1); setWordRu([...wordRu]) }}>-</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="load_title_and_button">
                    <div className="el_w50 fl_evenly synonims_list">
                        Старая картинка:
                        <OldImageTag />
                    </div>

                    <div className="el_w50 fl_evenly synonims_list">
                        Старый звук:
                        <OldSoudTag />
                    </div>
                </div>

                <div className="load_title_and_button">
                    <div className="el_w50 fl_evenly synonims_list">
                        Выберите картинку для загрузки:
                        <input type="file" onChange={loadImageFromDisk} />
                    </div>

                    <div className="el_w50 fl_evenly synonims_list">
                        Выберите звук для загрузки:
                        <input type="file" onChange={loadSoundFromDisk} />
                        <NewSoudTag />
                    </div>
                </div>
                {newImageResult &&
                    <>
                        <div className="fl_center">
                            <button className="constructor_button" onClick={() => zoom(-50)}>+</button>
                            <button className="constructor_button" onClick={() => zoom(50)}>-</button>
                        </div>
                        <div className="words_images fl_evenly">
                            <div className="crop_segment">
                                <canvas id="crop_canvas" ref={canvas} width="1000" height="1000"
                                    onClick={(e) => move(e)}
                                    onWheel={(e) => zoomScroll(e)}> </canvas>
                            </div>
                            <div className="crop_segment">
                                <img src={finalImage} />
                            </div>
                        </div>
                    </>
                }

            </div>
        </>
    );

}
