import Alert from "js/Alert";
import { Requester, ServerStatus } from "js/Requester";
import { WordsRoom } from "js/types";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import editIco from "static/icons/edit.svg";
import removeIco from "static/icons/remove.svg";
import publicIco from "static/icons/public.svg";
import privateIco from "static/icons/private.svg";
import { checkServerStatusAndDo } from "./course/jsx/common";
import { renderedWordsRoomsList } from "./course/jsx/renderedLists";

export default function WordsRoomsPool() {

    const navigate = useNavigate();

    const [allWordsRooms, setAllWordsRooms] = React.useState<WordsRoom[]>([]);
    const [allHiddenWordsRooms, setAllHiddenWordsRooms] = React.useState<WordsRoom[]>([]);
    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const alrt = (msg: string) => {
        setAlertMessage(msg);
        setTimeout(() => setAlertMessage(""), 2500);
    }

    const [wordFilter, setWordFilter] = React.useState<string>("");

    const loadWordsRooms = async () => {
        let [status, payload] = await Requester.getAllWordsRooms();
        checkServerStatusAndDo(status, payload, navigate, () => {
            payload && setAllWordsRooms(payload);
        });

        [status, payload] = await Requester.getAllHiddenWordsRooms();
        checkServerStatusAndDo(status, payload, navigate, () => {
            payload && setAllHiddenWordsRooms(payload);
        });
    }

    React.useEffect(() => {
        loadWordsRooms();
    }, [])

    const deleteWordsRoom = async (id: number) => {
        const [status, payload] = await Requester.deleteWordsRoom(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            setAllWordsRooms(allWordsRooms.filter((e: WordsRoom) => e.id !== id));
            alrt("Комната слов удалена");
        });
    }

    const togglePublish = async (e: WordsRoom) => {
        e.publish = !e.publish;
        const [status, payload] = await Requester.changeWordsRoom(e);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt(`Комната слов ${e.publish ? "опубликована" : "скрыта"}`);
            loadWordsRooms();
        });
    }

    const moveWordsRoom = async (id: number, dir: string) => {
        const [status, payload] =
            dir === "dec"
                ? await Requester.moveWordsRoomDecrease(id)
                : await Requester.moveWordsRoomIncrease(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            loadWordsRooms();
            alrt(`Комната слов перемещена`);
        });
    }

    const renderedWordsRooms = useMemo(() =>
        renderedWordsRoomsList(
            allWordsRooms,
            wordFilter,
            moveWordsRoom,
            togglePublish,
            deleteWordsRoom,
            navigate,
            null
        ),
        [wordFilter, allWordsRooms]);

    const renderedHiddenWordsRooms = useMemo(() =>
        renderedWordsRoomsList(
            allHiddenWordsRooms,
            wordFilter,
            moveWordsRoom,
            togglePublish,
            deleteWordsRoom,
            navigate,
            null
        ),
        [wordFilter, allHiddenWordsRooms]);

    return (
        <>
            <div className="main_container">
                <Alert message={alertMessage} />
                <input className="constructor_input" type="text" placeholder="filter words" value={wordFilter} onChange={(e) => setWordFilter(e.target.value)} />
                <div className="words_list">
                    {renderedWordsRooms}
                </div>
                <div className="words_list">
                    Скрытые комнаты слов:
                    {renderedHiddenWordsRooms}
                </div>

            </div>
        </>
    );
}
