import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import "static/css/zero.css";
import "static/css/enter.css";
import "static/css/train.css";
import "static/css/global.css";
import "static/css/constructor.css";
import "static/css/markdown.css";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
       <App />
    </BrowserRouter>
);

