import Alert from "js/Alert";
import { focusOnElement } from "js/helper";
import { Requester, ServerStatus } from "js/Requester";
import { emptySound, Sound } from "js/types";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { checkServerStatusAndDo } from "./course/jsx/common";


export default function SoundConstructor() {

    const navigate = useNavigate();

    const [sound, setSound] = React.useState<Sound>(emptySound());

    const id = useRef<number>(-1);
    const oldSound = useRef<string>("");
    const [isNewSound, setIsNewSound] = React.useState<boolean>(true);

    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const alrt = (msg: string) => {
        setAlertMessage(msg);
        setTimeout(() => setAlertMessage(""), 2500);
    }

    const tryToLoadSound = async () => {
        console.log("trying to load word");
        const urlParams = new URLSearchParams(window.location.search);
        const soundId: number | null = Number(urlParams.get("soundId"));

        id.current = soundId;

        if (!soundId) {
            return;
        }
        setIsNewSound(false);

        const [status, payload] = await Requester.getSound(soundId);
        if (status === ServerStatus.error) {
            alert("error");
            return;
        } else if (status === ServerStatus.unauth) {
            alert("wrong password!");
            navigate("/enter");
            return;
        } else if (status === ServerStatus.not_found) {
            alert("Не смог найти звук по id в адерсной строке");
            navigate("/sounds");
            return;
        }
        if (payload) {
            setSound(payload);
            oldSound.current = payload.sound;
        }
    }

    React.useEffect(() => {
        tryToLoadSound();
    }, []);

    const loadSoundFromDisk = (e: any) => {
        let tgt = e.target || window?.event?.srcElement,
            files = tgt.files;
        if (FileReader && files && files.length) {

            let fr = new FileReader();
            fr.onload = () => setSound({ ...sound, sound: fr.result as string });
            fr.readAsDataURL(files[0]);
        }
        const objUrl = URL.createObjectURL(e.target.files[0]);
        //(document.getElementById("sound_player") as HTMLAudioElement).src = objUrl;
    }

    const saveSound = async () => {
        console.log("saving sound");
        console.log(sound);
        if (!sound.title) {
            alert("Название обязательно!");
            return;
        }
        const [status, payload] = await Requester.saveSound(sound);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt("Слово сохранено");
            setSound(emptySound());
        });
    }
    const updateSound = async () => {
        console.log("saving word");
        if (!sound.title) {
            alert("Название обязательно!");
            return;
        }
        const [status, payload] = await Requester.changeSound(sound);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt("Слово изменено");
        });
    }
    const OldSoudTag = () => (<>
        {oldSound.current ?
            <>
                <div className="audio-play-button" onClick={() => {
                    const audio = new Audio(Requester.static_url + "words/sounds/" + oldSound.current + ".mp3");
                    audio.play();
                }}>
                    Play Audio
                </div>
                <audio id="audio-file" controls>
                    <source src={Requester.static_url + "words/sounds/" + oldSound.current + ".mp3"} type="audio/mp3" />
                    Your browser does not support the audio element.
                </audio>
            </>
            : "NO"
        }
    </>);

    const NewSoudTag = () => (<>
        {sound ?
            <>
                <div className="audio-play-button" onClick={() => {
                    const audio = new Audio(sound.sound);
                    audio.play();
                }}>
                    Play Audio
                </div>
            </>
            : ""
        }
    </>);

    return (
        <>
            <Alert message={alertMessage} />
            <div className="main_container">

                <button onClick={() => navigate("/sounds")} className="constructor_button">Назад</button>
                <button className="constructor_button" onClick={() => isNewSound ? saveSound() : updateSound()}>Сохранить звук</button>

                <input className="constructor_input el_w90" id="kz_input" placeholder="kz" type="text" value={sound.title}
                    onChange={ch => {
                        setSound({...sound, title: ch.target.value});
                    }} />

            </div>
            <div className="load_title_and_button">
                <div className="el_w50 fl_evenly synonims_list">
                    Старый звук:
                    <OldSoudTag />
                </div>
            </div>

            <div className="load_title_and_button">
                <div className="el_w50 fl_evenly synonims_list">
                    Выберите звук для загрузки:
                    <input type="file" onChange={loadSoundFromDisk} />
                    <NewSoudTag />
                </div>
            </div>
        </>
    );

}
