export type LoginResponse = {
    ok: boolean,
    token: string,
}

export type Word = {
    id: number,
    kazakh: string[],
    russian: string[],
    image: string,
    sound: string
}

export type Sound = {
    id: number,
    title: string,
    sound: string
}

export const emptySound = () : Sound => {
    return {
        id: -1,
        title: "",
        sound: ""
    }
}

export type WordsRoom = {
    id: number,
    title: string,
    publish: boolean,
    hidden: boolean,
    position: number,
    wordList: Word[]
}

export const emptyWordsRoom = () : WordsRoom => {
    return {
        id: -1,
        title: "",
        publish: false,
        hidden: false,
        position: -1,
        wordList: []
    }
}

export type WordsChapter = {
    id: number,
    title: string,
    position: number,
    publish: boolean,
    wordsRoomsList: WordsRoom[],
}

export const emptyWordsChapter = () : WordsChapter => {
    return {
        id: -1,
        title: "",
        position: -1,
        publish: false,
        wordsRoomsList: []
    }
}

export type ConstructorChapter = {
    id: number,
    title: string,
    position: number,
    publish: boolean,
    topicsList: ConstructorTopic[],
}

export const emptyConstructorChapter = () : ConstructorChapter => {
    return {
        id: -1,
        title: "",
        position: -1,
        publish: false,
        topicsList: []
    }
}

export type ConstructorTopic = {
    id: number,
    title: string,
    markdownText: string,
    publish: boolean,
    position: number,
    trainsList: ConstructorTrain[]
}

export const emptyConstructorTopic = () : ConstructorTopic => {
    return {
        id: -1,
        title: "",
        markdownText: "",
        position: -1,
        publish: false,
        trainsList: []
    }
}

export enum AutoTaskTemplateEnum {
    CLASSIFY_SELECT = "CLASSIFY_SELECT",
    ENDING_PICK_ALL = "ENDING_PICK_ALL",
    SMALL_TRANSLATE = "SMALL_TRANSLATE",
}

export type AutoTaskTemplate = {
    id: number,
    text: string,
    activeTasks: number,
    templateType: AutoTaskTemplateEnum 
}

export type ConstructorTrain = {
    id: number,
    title: string,
    publish: boolean,
    position: number,
    autoTasksTemplatesList: AutoTaskTemplate[],
}

export const emptyConstructorTrain = () : ConstructorTrain => {
    return {
        id: -1,
        title: "",
        publish: false,
        position: -1,
        autoTasksTemplatesList: [],
    }
}
