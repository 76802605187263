import Alert from "js/Alert";
import { Requester, ServerStatus } from "js/Requester";
import { ConstructorTopic, Word, WordsChapter, emptyWordsChapter, WordsRoom } from "js/types";
import React, { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import soundIco from "static/icons/sound.svg";
import nosoundIco from "static/icons/nosound.svg";
import plusIco from "static/icons/plus.svg";
import minusIco from "static/icons/minus.svg";
import { renderedTopicsList, renderedWordsRoomsList } from "./jsx/renderedLists";
import { checkServerStatusAndDo } from "./jsx/common";

export default function WordsChaptersConstructor() {

    const navigate = useNavigate();

    const [isNewWordsChapter, setIsNewWordsChapter] = React.useState<boolean>(true);

    const [freeWordsRooms, setFreeWordsRooms] = React.useState<WordsRoom[]>([]);

    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const alrt = (msg: string) => {
        setAlertMessage(msg);
        setTimeout(() => setAlertMessage(""), 2500);
    }

    const [filterText, setFilterText] = React.useState<string>("");

    const [wordsChaper, setWordsChapter] = React.useState<WordsChapter>(emptyWordsChapter());

    const id = useRef<number>(-1);

    const tryToLoadWordsChapter = async () => {
        console.log("trying to load wordsChaper");
        const urlParams = new URLSearchParams(window.location.search);
        const wordsChaperId: number | null = Number(urlParams.get("wordsChapterId"));

        id.current = wordsChaperId;
        console.log(id.current);

        if (!wordsChaperId) {
            return;
        }
        setIsNewWordsChapter(false);

        const [status, payload] = await Requester.getWordsChapter(wordsChaperId);
        if (status === ServerStatus.error) {
            alert("error");
            return;
        } else if (status === ServerStatus.unauth) {
            alert("wrong password!");
            navigate("/enter");
            return;
        } else if (status === ServerStatus.not_found) {
            alert("Не смог найти слово по id в адерсной строке");
            navigate("/words");
            return;
        }
        if (payload) {
            id.current = payload.id;
            setWordsChapter(payload);
            console.log(payload);
        }
    }

    const loadFreeWordsRooms = async () => {
        const [status, payload] = await Requester.getAllFreeWordsRooms();
        checkServerStatusAndDo(status, payload, navigate, () => {
            payload && setFreeWordsRooms(payload);
        });
    }

    React.useEffect(() => {
        tryToLoadWordsChapter();
        loadFreeWordsRooms();
    }, [])


    const saveWordsChapter = async () => {
        if (!wordsChaper.title) {
            alert("Название необходимо");
            return;
        }
        const [status, payload] = await Requester.saveWordsChapter(wordsChaper);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt(`Глава сохранена`);
            setWordsChapter(emptyWordsChapter());
            loadFreeWordsRooms();
        });
    }

    const updateWordsChapter = async () => {
        if (!wordsChaper.title) {
            alert("Название необходимо");
            return;
        }
        const [status, payload] = await Requester.changeWordsChapter(wordsChaper);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt("Глава изменена");
        });
    }

    const moveWordsRoom = async (id: number, dir: string) => {
        const [status, payload] =
            dir === "dec"
                ? await Requester.moveWordsRoomDecrease(id)
                : await Requester.moveWordsRoomIncrease(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            tryToLoadWordsChapter();
            loadFreeWordsRooms();
            alrt(`Тема перемещена`);
        });
    }

    const toggleWordsRoomPublish = async (e: WordsRoom) => {
        e.publish = !e.publish;
        const [status, payload] = await Requester.changeWordsRoom(e);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt(`Комната слов ${e.publish ? "опубликована" : "скрыта"}`);
            loadFreeWordsRooms();
            tryToLoadWordsChapter();
        });
    }

    const deleteWordsRoom = async (id: number) => {
        const [status, payload] = await Requester.deleteWordsRoom(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            setFreeWordsRooms(freeWordsRooms.filter((e: WordsRoom) => e.id !== id));
            setWordsChapter({ ...wordsChaper, wordsRoomsList: wordsChaper.wordsRoomsList.filter((e: WordsRoom) => e.id !== id) });
            alrt(`Комната слов удалена`);
        })
    }

    const renderedWordsChapterssTopicsList = useMemo(() =>
        renderedWordsRoomsList(
            wordsChaper.wordsRoomsList,
            "",
            moveWordsRoom,
            toggleWordsRoomPublish,
            deleteWordsRoom,
            navigate,
            (e: WordsRoom) =>
                <img src={minusIco} onClick={() => {
                    wordsChaper.wordsRoomsList.splice(wordsChaper.wordsRoomsList.indexOf(e), 1);
                    freeWordsRooms.push(e);
                    setWordsChapter({ ...wordsChaper });
                }} />
        ),
        [wordsChaper, freeWordsRooms]);

    const renderedFreeWordsRooms = useMemo(() =>
        renderedWordsRoomsList(
            freeWordsRooms,
            "",
            moveWordsRoom,
            toggleWordsRoomPublish,
            deleteWordsRoom,
            navigate,
            (e: WordsRoom) =>
                <img src={plusIco} onClick={() => {
                    wordsChaper.wordsRoomsList.push(e);
                    freeWordsRooms.splice(freeWordsRooms.indexOf(e), 1);
                    setWordsChapter({ ...wordsChaper });
                }} />
        ),
        [wordsChaper, freeWordsRooms]);


    return (
        <>
            <Alert message={alertMessage} />
            <div className="main_container">

                <button onClick={() => navigate("/wordsChapters")} className="constructor_button">Назад</button>
                <button className="constructor_button" onClick={() => isNewWordsChapter ? saveWordsChapter() : updateWordsChapter()}>Сохранить главу</button>

                <div className="load_title_and_button">
                    <div className="el_w50">
                        Название главы
                    </div>

                    <div className="el_w50">
                        <input className="constructor_input el_w100" id="kz_input" placeholder="title" type="text" value={wordsChaper.title}
                            onChange={e => setWordsChapter({ ...wordsChaper, title: e.target.value })} />
                    </div>
                </div>

                <div className="words_list">
                    Комнаты слов в этой главе:
                    {renderedWordsChapterssTopicsList}
                </div>

                <div className="words_list">
                    Бесхозные комнаты слов:
                    {renderedFreeWordsRooms}
                </div>

            </div>
        </>
    );

}
