import Alert from "js/Alert";
import { Requester, ServerStatus } from "js/Requester";
import { ConstructorTopic, ConstructorTrain, emptyConstructorTopic, Word } from "js/types";
import React, { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import soundIco from "static/icons/sound.svg";
import nosoundIco from "static/icons/nosound.svg";
import plusIco from "static/icons/plus.svg";
import minusIco from "static/icons/minus.svg";
import { checkServerStatusAndDo } from "./jsx/common";
import { renderedTrainsList } from "./jsx/renderedLists";

export default function TopicConstructor() {

    const navigate = useNavigate();

    const [isNewTopic, setIsNewTopic] = React.useState<boolean>(true);
    const [freeTrains, setFreeTrains] = React.useState<ConstructorTrain[]>([]);

    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const alrt = (msg: string) => {
        setAlertMessage(msg);
        setTimeout(() => setAlertMessage(""), 2500);
    }

    const [topic, setTopic] = React.useState<ConstructorTopic>(emptyConstructorTopic());

    const [filterText, setFilterText] = React.useState<string>("");

    const id = useRef<number>(-1);

    const tryToLoadTopic = async () => {
        console.log("trying to load topic");
        const urlParams = new URLSearchParams(window.location.search);
        const topicId: number | null = Number(urlParams.get("topicId"));

        id.current = topicId;

        if (!topicId) {
            return;
        }
        setIsNewTopic(false);

        const [status, payload] = await Requester.getTopic(topicId);
        if (status === ServerStatus.error) {
            alert("error");
            return;
        } else if (status === ServerStatus.unauth) {
            alert("wrong password!");
            navigate("/enter");
            return;
        } else if (status === ServerStatus.not_found) {
            alert("Не смог найти слово по id в адерсной строке");
            navigate("/words");
            return;
        }
        if (payload) {
            setTopic(payload);
            id.current = payload.id;
        }
    }

    const loadFreeTrains = async () => {
        const [status, payload] = await Requester.getAllFreeTrains();
        checkServerStatusAndDo(status, payload, navigate, () => {
            payload && setFreeTrains(payload);
        });
    }

    React.useEffect(() => {
        tryToLoadTopic();
        loadFreeTrains();
    }, [])


    const saveTopic = async () => {
        if (!topic.title) {
            alert("Название необходимо");
            return;
        }
        const [status, payload] = await Requester.saveTopic(topic);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt(`Тема сохранена`);
            setTopic(emptyConstructorTopic());
            loadFreeTrains();
        });
    }

    const updateTopic = async () => {
        if (!topic.title) {
            alert("Название необходимо");
            return;
        }
        const [status, payload] = await Requester.changeTopic(topic);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt(`Тема изменена`);
        });
    }

    const toggleTrainPublish = async (e: ConstructorTrain) => {
        e.publish = !e.publish;
        const [status, payload] = await Requester.changeTrain(e);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt(`Тренировка ${e.publish ? "опубликована" : "скрыта"}`);
            loadFreeTrains();
        });
    }

    const moveTrain = async (id: number, dir: string) => {
        const [status, payload] =
            dir === "dec"
                ? await Requester.moveTrainDecrease(id)
                : await Requester.moveTrainIncrease(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            tryToLoadTopic();
            loadFreeTrains();
            alrt(`Тренировка перемещена`);
        });
    }

    const deleteTrain = async (id: number) => {
        const [status, payload] = await Requester.deleteTrain(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt(`Тренировка удалена`);
            tryToLoadTopic();
            loadFreeTrains();
        });
    }

    const renderedTopicsTrainsList = useMemo(() =>
        renderedTrainsList(
            topic.trainsList,
            "",
            moveTrain,
            toggleTrainPublish,
            null,
            navigate,
            (e: ConstructorTrain) =>
                    <img src={minusIco} onClick={() => {
                        topic.trainsList.splice(topic.trainsList.indexOf(e), 1);
                        freeTrains.push(e);
                        setTopic({...topic});
                    }} />
        ),
        [topic, freeTrains]);

    const renderedFreeTrains = useMemo(() =>
        renderedTrainsList(
            freeTrains,
            "",
            moveTrain,
            toggleTrainPublish,
            null,
            navigate,
            (e: ConstructorTrain) =>
                    <img src={plusIco} onClick={() => {
                        topic.trainsList.push(e);
                        freeTrains.splice(freeTrains.indexOf(e), 1);
                        setTopic({...topic});
                    }} />
        ),
        [topic, freeTrains]);

    return (
        <>
            <Alert message={alertMessage} />
            <div className="main_container">

                <button onClick={() => navigate("/topics")} className="constructor_button">Назад</button>
                <button className="constructor_button" onClick={() => isNewTopic ? saveTopic() : updateTopic()}>Сохранить тему</button>

                <div className="load_title_and_button">
                    <div className="el_w50">
                        Название темы
                    </div>

                    <div className="el_w50">
                        <input className="constructor_input el_w100" id="kz_input" placeholder="title" type="text" value={topic.title}
                            onChange={e => setTopic({ ...topic, title: e.target.value })} />
                    </div>
                </div>
                
                <div className="load_title_and_button">
                    <div className="el_w50 vertical_elements">
                        Теория в формате html
                        <textarea className="constructor_input theory_textarea el_w50" value={topic.markdownText} onChange={(e) => setTopic({...topic, markdownText: e.target.value})} />
                    </div>

                    <div className="el_w50 theory_preview">
                        <div dangerouslySetInnerHTML={{__html: topic.markdownText}}/>
                    </div>
                </div>

                <div className="words_list">
                    Тренировки в этой теме:
                    {renderedTopicsTrainsList}
                </div>

                <div className="words_list">
                    Бесхозные тренировки:
                    {renderedFreeTrains}
                </div>

            </div>
        </>
    );

}
