import Alert from "js/Alert";
import { Requester, ServerStatus } from "js/Requester";
import { ConstructorChapter } from "js/types";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { renderedChaptersList } from "./jsx/renderedLists";
import { checkServerStatusAndDo } from "./jsx/common";

export default function ChapterPool() {

    const navigate = useNavigate();

    const [allChapters, setAllChapters] = React.useState<ConstructorChapter[]>([]);

    const [chapterFilter, setChapterFilter] = React.useState<string>("");

    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const alrt = (msg: string) => {
        setAlertMessage(msg);
        setTimeout(() => setAlertMessage(""), 2500);
    }

    const loadChapters = async () => {
        const [status, payload] = await Requester.getAllChapters();
        checkServerStatusAndDo(status, payload, navigate, () => {
            payload && setAllChapters(payload);
        });
    }

    React.useEffect(() => {
        loadChapters();
    }, [])

    const deleteChapter = async (id: number) => {
        const [status, payload] = await Requester.deleteChapter(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            setAllChapters(allChapters.filter((e: ConstructorChapter) => e.id !== id));
            alrt(`Глава удалена`);
        });
    }

    const moveChapter = async (id: number, dir: string) => {
        const [status, payload] =
            dir === "dec"
                ? await Requester.moveChapterDecrease(id)
                : await Requester.moveChapterIncrease(id);
        checkServerStatusAndDo(status, payload, navigate, () => {
            loadChapters();
            alrt(`Глава пермещена`);
        });
    }

    const togglePublish = async (e: ConstructorChapter) => {
        e.publish = !e.publish;
        const [status, payload] = await Requester.changeChapter(e);
        checkServerStatusAndDo(status, payload, navigate, () => {
            alrt(`Глава ${e.publish ? "опубликована" : "скрыта"}`);
            loadChapters();
        });
    }

    const renderedChapters = useMemo(() =>
        renderedChaptersList(
            allChapters,
            chapterFilter,
            moveChapter,
            togglePublish,
            deleteChapter,
            navigate),
        [chapterFilter, allChapters]);

    return (
        <div className="main_container">
            <Alert message={alertMessage} />
            <input className="constructor_input" type="text" placeholder="Поиск" value={chapterFilter} onChange={(e) => setChapterFilter(e.target.value)} />
            <div className="words_list">
                {renderedChapters}
            </div>
        </div>
    );
}
