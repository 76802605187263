import { Requester, ServerStatus } from "js/Requester";
import { ConstructorTopic } from "js/types";

export const toggleTopicPublish = async (e: ConstructorTopic, navigate: any, okFunc: any) => {
    let action = "скрыта";
    let publish = false;
    if (!e.publish) {
        action = "опубликована";
        publish = true;
    }
    e.publish = publish;
    const [status, payload] = await Requester.changeTopic(e);
    if (status === ServerStatus.error) {
        alert("error");
        return;
    } else if (status === ServerStatus.unauth) {
        alert("wrong password!");
        navigate("/enter");
        return;
    }
    if (payload) {
        okFunc();
    }
}

export const checkServerStatusAndDo = async (status: ServerStatus, payload: any, navigate: any, doFunc: any) => {
    console.log("STATUS = ", status)
    if (status === ServerStatus.error) {
        alert("error");
        return;
    } else if (status === ServerStatus.unauth) {
        alert("wrong password!");
        navigate("/enter");
        return;
    } else if (status === ServerStatus.unacceptable) {
        alert("Элемент имеет детей. Сперва удалите их");
        return;
    }
    if (payload)
        doFunc();
}
